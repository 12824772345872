<template>
  <div
    class="callout block h-full w-full bg-brand-marigold bg-opacity-75 bg-cover bg-top bg-no-repeat font-montserrat"
    :class="{ 'animate-pulse': pending, 'is-empty': !uuid }"
    :style="{ backgroundImage }"
  >
    <a
      v-if="uuid"
      class="flex h-full w-full flex-col justify-end"
      :class="{ 'has-image img-link': image?.src }"
      :href="
        locale === 'es' ? 'https://www.unity.org/es' : 'https://www.unity.org'
      "
    >
      <h3
        class="bg-gradient-to-t from-slate-900 to-transparent p-4 text-center text-2xl leading-tight tracking-tight text-white"
      >
        {{ t('The Latest on Unity.org') }}
      </h3>
    </a>
  </div>
</template>

<script lang="ts" setup>
const { t, locale } = useI18n()

const props = defineProps<{
  status: string
  uuid: string
  path: string
  image?: {
    src: string
    height: number
    width: number
    alt?: string
    mime: string
  }
}>()

const pending = computed(() => props.status === 'pending')

const backgroundImage = computed(() => {
  if (!props.image) return 'initial'

  const { src } = props.image

  return `url(${src})`
})
</script>
