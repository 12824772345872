import { parseURL, stringifyParsedURL } from 'ufo'
import { defineStore } from 'pinia'
import { watch, type Ref } from 'vue'

import { info } from '~/utils/logging'

const DAILY_WORD_APP_UUID = 'b4033bd3-80ff-47b2-a331-2104b23ff358' as const

const AliasKeys = [
  'default',
  'en',
  'es',
  'internal',
  'path',
  'uuid',
  'promotion_destination',
] as const

type AliasKey = (typeof AliasKeys)[number]

export type Promotion = {
  id: string
  type: string
  title: string
  badge: string
  locale: string
  isPublished: boolean
  canonicalDate: Date
  url: string
  aliases: Record<AliasKey, string>
  body?: {
    format?: string
    processed: string
    summary?: string
    trimmed: string
    value: string
  }
}

type FetchParams = {
  limit?: number
  locale?: string | Ref<string>
}

const fetchPromos = async (args?: FetchParams) => {
  const { limit = 50, locale = 'en' } = args || {}

  const promos = await $fetch('/api/data/promotions', {
    params: {
      limit,
      locale,
      matching: [DAILY_WORD_APP_UUID],
    },
  })

  return promos.map(({ path: _path, ...promo }) => {
    const path = stringifyParsedURL({
      ...parseURL(_path),
      host: 'www.unity.org',
      protocol: 'https:',
    })

    return {
      ...promo,
      path,
    }
  })
}

export const usePromotionsStore = defineStore('promotions', () => {
  const { locale } = useI18n()

  const { isLoading, state, isReady, execute } = useAsyncState(
    fetchPromos,
    [],
    { immediate: false },
  )

  const refresh = async () => {
    info('[promotions] refreshing promotions')
    return execute(0, { locale })
  }

  watch(locale, refresh, { immediate: true })

  return { data: state, promotions: state, isReady, isLoading, refresh }
})
