<template>
  <NuxtLink class="relative text-3xl" :to="localePath('/favorites')">
    <span v-if="shouldAnimate" class="absolute top-0 right-0 flex h-2 w-2">
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-600 opacity-75 motion-reduce:animate-none"
      ></span>
      <span
        class="relative inline-flex rounded-full h-2 w-2 bg-brand-peacock"
      ></span>
    </span>
    <Icon class="icon" name="material-symbols:bookmarks" />
    <span class="label sr-only">{{ t('Favorites') }}</span>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'
const { t } = useI18n()
const localePath = useLocalePath()
const { onAdded } = useFavorites()

const shouldAnimate = ref(false)
onAdded((items) => {
  console.log('favs.onAdded', { items, pre: true })
})

onAdded(async (items) => {
  console.log('favs.onAdded', { items })
  shouldAnimate.value = true
  await promiseTimeout(5000)
  shouldAnimate.value = false
})
</script>
