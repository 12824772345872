<template>
  <div
    v-if="pending || uuid"
    ref="target"
    class="callout block h-full w-full bg-brand-turquoise bg-opacity-75 bg-contain bg-top bg-no-repeat font-montserrat"
    :class="{ 'animate-pulse': pending, 'is-empty': !uuid }"
    :style="{ backgroundImage }"
    :data-uuid="uuid"
  >
    <template v-if="uuid">
      <a
        class="flex h-full w-full flex-col justify-end"
        :class="{ 'has-image img-link': image?.src }"
        :href="path"
      >
        <h3
          class="bg-gradient-to-t from-slate-900 to-transparent p-4 text-center text-2xl leading-tight tracking-tight text-white"
        >
          {{ t('Spirituality & Health') }}
        </h3>
      </a>
    </template>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const IDENTITY_SH_MAGAZINE = '4a5949fc-1503-465f-9073-b66aa4388a70'

const props = defineProps<{
  status: string
  uuid: string
  path: string
  image?: {
    src: string
    height: number
    width: number
    alt?: string
    mime: string
  }
}>()

const pending = computed(() => props.status === 'pending')

const backgroundImage = computed(() => {
  if (!props.image) return 'initial'

  const { src } = props.image

  return `url(${src})`
})
</script>
