<template>
  <div class="pt-toolbar">
    <div class="app">
      <main class="app-main">
        <div ref="main" class="app-page">
          <slot />
        </div>
      </main>

      <ClientOnly fallback-tag="div">
        <NotificationStack class="app-alerts w-full" />
      </ClientOnly>

      <section class="app-highlights pb-8 xl:min-w-[380px]">
        <h2
          class="section-heading font-montserrat text-sm uppercase"
          @click="shouldHighlight = false"
        >
          {{ t('More from Unity.org') }}
        </h2>
        <AppCalloutGrid class="py-4" />
      </section>

      <aside
        class="app-aside mx-4 mt-4"
        :class="{
          highlight: shouldHighlight,
        }"
      ></aside>

      <AppNavbar class="app-navbar" />
    </div>
    <div class="app-topbar">
      <AppTopbar class="contain max-w-[1400px] px-0" />
    </div>
  </div>

  <teleport to="body">
    <div
      id="toasts"
      class="toast-center toast-bottom toast -translate-y-40 z-10 md:-translate-y-8"
      aria-modal="true"
    />
  </teleport>
</template>

<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'

const { t } = useI18n()
const route = useRoute()

const main = ref<HTMLElement>()

// @todo: set to 'true' only once per session start
const isSessionStart = useSessionStorage('is-session-start', true)
const shouldHighlight = ref<boolean>(true)

const { height: windowHeight } = useWindowSize()
const { top: maintop, bottom: mainBottom } = useElementBounding(main)

const { css } = useStyleTag(
  '.app-aside.highlight { --aside-translate-y: -50vh; }',
)

const translateY = computed(() =>
  Math.ceil(unref(mainBottom) - unref(maintop) - unref(windowHeight) * 0.25),
)

const updateTranslateY = () => {
  css.value = `.app-aside.highlight { --aside-translate-y: -${unref(
    translateY,
  )}px; }`
}

const init = async () => {
  if (!isSessionStart) {
    shouldHighlight.value = false
    return
  }

  if (!shouldHighlight.value) return
  updateTranslateY()

  await promiseTimeout(5000)
  shouldHighlight.value = false
}

watch(mainBottom, init)
onMounted(init)
</script>

<style lang="postcss" scoped>
.app-highlights {
  @apply mt-6 shadow shadow-gray-200 dark:shadow-gray-700;

  -webkit-box-shadow: 0px -20px 45px 5px var(--tw-shadow-color);
  -moz-box-shadow: 0px -20px 45px 5px var(--tw-shadow-color);
  box-shadow: 0px -20px 45px 5px var(--tw-shadow-color);

  @screen lg {
    margin-top: 0;
    box-shadow: none;
  }
}

.app-aside {
  @apply transition-transform duration-[2s] ease-in-out;
}

.highlight {
  @apply min-h-[80vh] duration-1000;

  --aside-translate-y: -60vh;
  transform: translateY(var(--aside-translate-y));
}
</style>
