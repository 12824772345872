<template>
  <div
    v-show="isLoading || promotion"
    ref="target"
    class="flip block h-full w-full bg-brand-turquoise px-4 py-2 font-montserrat"
    :class="{ 'animate-pulse-': isPending, 'is-empty': !promotion }"
  >
    <template v-if="promotion">
      <a
        class="inline-block h-full w-full space-y-2"
        :href="promotion.path"
        :data-uuid="promotion.uuid"
        Z
      >
        <h4
          v-if="promotion?.badge"
          class="text-base uppercase leading-none text-brand-marigold"
        >
          {{ promotion.badge }}
        </h4>
        <h3 class="text-2xl leading-tight text-white">
          {{ promotion.label }}
        </h3>
      </a>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { draw } from 'radash'
import { storeToRefs } from 'pinia'
import { useIntervalFn, whenever, set } from '@vueuse/core'
import { debug } from '~/utils/logging'

const { PROMOTION_CHANGE_INTERVAL } = useAppConfig() as {
  PROMOTION_CHANGE_INTERVAL: number
}

type Promotion = ReturnType<typeof usePromotionsStore>['data'][number]

const store = usePromotionsStore()

const { data, isReady, isLoading } = storeToRefs(store)
const isPending = ref<boolean>(false)

const promotion = useSessionStorage<Promotion>('current-promotion', null, {
  serializer: {
    read: (v: any) => (v ? JSON.parse(v) : null),
    write: (v: any) => JSON.stringify(v),
  },
})

const getRandomPromo = () => {
  const _promos = unref(data)
  if (!_promos?.length) return

  const next = draw(_promos)
  if (!next) return

  return next
}

const setPromo = (promo?: Promotion) => {
  if (!promo) return
  set<Promotion>(promotion, promo)
}

const updatePromo = async () => {
  const next = getRandomPromo()
  if (!next) return

  isPending.value = true
  if (apply) await apply('initial')
  setPromo(next)
  if (apply) await apply('enter')
  isPending.value = false
}

setPromo(getRandomPromo())

whenever(isReady, () => {
  updatePromo()
  debug(`[promotions] Shuffling ${data.value.length} promos!`)
})

useIntervalFn(updatePromo, PROMOTION_CHANGE_INTERVAL)

const target = ref<HTMLElement>()
const { apply } = useMotion(target, {
  initial: { opacity: 0, rotateY: 90 },
  enter: { opacity: 1, rotateY: 0 },
  leave: { opacity: 0, rotateY: 90 },
})
</script>
