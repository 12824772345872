<template>
  <div
    ref="container"
    class="swipe-container isolate grid select-none grid-cols-1 grid-rows-1 place-content-center rounded-md"
    :class="{
      'bg-slate-400 bg-opacity-95 outline-dashed outline-1 -outline-offset-8 outline-slate-200':
        isSwiping,
    }"
    :style="{ 'min-height': containerHeight }"
  >
    <div
      ref="target"
      class="swipe-target col-span-full row-span-full"
      :class="{ 'transition-all duration-200': !isSwiping }"
      :style="targetStyle"
    >
      <slot />
    </div>

    <button
      class="action dismiss absolute right-2 top-2"
      :style="targetStyle"
      @click="reveal"
    >
      <Icon name="material-symbols:close" />
      <span class="label sr-only">{{ t('Dismiss') }}</span>
    </button>
  </div>

  <teleport to="#toasts">
    <NotificationTimeout
      v-if="isRevealed"
      v-motion
      :initial="{ y: 500, z: -100, scale: 0.5 }"
      :enter="{ y: 0, z: 1, scale: 1 }"
      :duration="timeout"
      @done="confirm"
    >
      <div
        class="flex w-max touch-manipulation flex-row items-center justify-start gap-3 p-4 pb-3 pt-2"
      >
        <span>{{ t('Notification dismissed.') }}</span>
        <button class="btn-ghost btn-sm btn" @click="cancel">
          <Icon class="text-3xl" name="material-symbols:undo" />
          <span class="label sr-only">{{ t('Would you like to undo?') }}</span>
          <span aria-hidden="true">{{ t('Undo?') }}</span>
        </button>
      </div>
    </NotificationTimeout>
  </teleport>
</template>

<script lang="ts" setup>
import { useSwipe } from '@vueuse/core'

const { t } = useI18n()
const { notificationTimeout: timeout } = useAppConfig() as {
  notificationTimeout: number
}

const emit = defineEmits(['cancel', 'confirm', 'dismiss'])

const target = ref<HTMLElement | null>(null)
const container = ref<HTMLElement | null>(null)
const containerWidth = computed(() => container.value?.offsetWidth)
const containerHeight = computed(() => target.value?.offsetHeight || 350)

const left = ref('0')
const opacity = ref(1)

const targetStyle = computed(() => ({
  transform: `translateX(${left.value})`,
  opacity: opacity.value,
}))

const reset = () => {
  left.value = '0'
  opacity.value = 1
}

const { isRevealed, reveal, confirm, cancel, onReveal, onConfirm, onCancel } =
  useConfirmDialog()

onReveal(() => {
  left.value = '100%'
  opacity.value = 0
  emit('dismiss')
})

onConfirm(() => emit('confirm'))

onCancel(() => {
  reset()
  emit('cancel')
})

const { direction, isSwiping, lengthX } = useSwipe(target, {
  onSwipe(e: TouchEvent) {
    if (!containerWidth.value) return
    if (direction.value !== 'right') return

    if (lengthX.value < 0) {
      const length = Math.abs(lengthX.value)
      left.value = `${length}px`
      opacity.value = 1.1 - length / containerWidth.value
    } else {
      left.value = '0'
      opacity.value = 1
    }
  },
  onSwipeEnd(e: TouchEvent, direction) {
    if (
      lengthX.value < 0 &&
      containerWidth.value &&
      Math.abs(lengthX.value) / containerWidth.value >= 0.5
    ) {
      left.value = '100%'
      opacity.value = 0
      reveal()
    } else {
      left.value = '0'
      opacity.value = 1
    }
  },
})
</script>
